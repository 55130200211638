import React from 'react';
import './styles.scss';

const StaticTemplate = ({ data }) => {
  const { markdownRemark } = data;
  const { frontmatter, html } = markdownRemark;
  return (
    <section className="StaticTemplate">
      <article>
        <header>
          <h1>{frontmatter.title}</h1>
          <p>{frontmatter.details}</p>
        </header>
        <main dangerouslySetInnerHTML={{ __html: html }} />
      </article>
    </section>
  )
}

export default StaticTemplate;