import React from 'react';
import { graphql } from 'gatsby';
import SEO from '../components/seo';
import StaticTemplate from '@templates/StaticTemplate';
import { Footer, Header, Page } from '@components';

export const pageQuery = graphql`
  query LegalPageQuery {
    markdownRemark(fileAbsolutePath: { regex: "/legal.md/" }) {
      html
      frontmatter {
        title
      }
    }
  }
`

const PrivacyPage = ({ data }) => (
  <Page>
    <SEO title="Legal" />
    <Header />
      <StaticTemplate data={data} />
    <Footer />
  </Page>
)

export default PrivacyPage
